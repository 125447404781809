import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondPageHeader from "../container/secondPageHaaderContainer"
import PlanContent from "../container/planContentContainer"
import CallToAction from "../container/ctaContainer"

const PlanPage = () => (
  <Layout>
    <Seo title="料金プラン | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | wakachie" />
    <SecondPageHeader englishTitle="- PLAN -" mainTitle="料金プラン" isShowTrialLink />
    <PlanContent />
    <CallToAction />
  </Layout>
)

export default PlanPage
